import Lang from "lang.js";

var lang = new Lang();
var applicationMessages = await fetch("/lang.dist.js")
    .then((response) => response.json())
    .then((json) => {
        return json;
    });
lang.setMessages(applicationMessages);
export default function InputError({ message, className = '', ...props }) {
    return message ? (
        <label {...props} className={'form_group_error' + className}>
            {message}
        </label>
    ) : null;
}
